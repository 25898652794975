<template>
  <div class="service-details-page">
    <HeaderFive>
      <img slot="logo" src="../assets/img/logo/portfolio.png" />
    </HeaderFive>

    <!-- Start Bradcaump area -->
    <div class="bradcaump_area">
      <div class="parallaxContainer paralaxbg--3" data-black-overlay="6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center bradcaump_inner">
                <h2 class="bradcaump-title">
                  DIBUJOS A MANO ALZADA
                </h2>
                <p>De una imagen a tu pared</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- End Bradcaump area -->

    <!-- Start Service Details Area -->
    <div class="rf-service-details section-ptb-xl">
      <v-container class="container">
        <v-row class="mb--80">
          <v-col md="6" cols="12" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../assets/img/portfolio/portfolio-single-1.jpg"
                  alt="portfolio"
                />
              </div>
            </div>
          </v-col>
          <v-col md="6" cols="12" class="">
            <div class="rp-port-details-inner">
              <div class="content">
                <p>
                  ¿Te gustaría tener un dibujo personalizado y único creado a mano alzada? Si es así, ¡has llegado al lugar adecuado! Como pintor de dibujos a mano, ofrezco la creación de dibujos a mano personalizados para cualquier ocasión.
                </p>
                <div class="mt--40">
                  <h4 class="heading-title">¿Cómo funciona?</h4>
                  <p>
                    Es muy fácil. Todo lo que necesitas hacer es enviarme una foto y darme algunas indicaciones simples sobre lo que te gustaría ver en el dibujo. Puede ser una foto de un lugar especial, una persona, una mascota, o cualquier otra cosa que quieras. También puedes darme algunas instrucciones sobre el estilo o el tema que te gustaría que tenga el dibujo.</p>
                    <p>Una vez que tenga toda la información que necesito, empezaré a trabajar en tu dibujo a mano alzada. Utilizo principalmente la técnica de la plumilla para crear dibujos detallados y precisos. Cada dibujo es único y creado a medida para ti, por lo que puedes estar seguro de que tendrás un dibujo personalizado que nadie más tendrá.</p>
                  <!-- <ul class="liststyle bullet">
                    <li>Yet this above sewed flirted opened ouch</li>
                    <li>Goldfinch realistic sporadic ingenuous</li>
                    <li>
                      Abominable this abidin far successfully then like piquan
                    </li>
                    <li>Risus commodo viverra</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                  </ul> -->
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" cols="12" order="2" order-lg="1">
            <div class="rp-port-details-inner">
              <div class="content">
                <!-- <p>
                  Etia reprimi no pri, vix no reque dolore di sentiet, vel at
                  wisi mazim feugiat. Ne ali quam apeirian mel, an ese choro
                  regione virtute per. iusto oporter and. Ius vitae parte repudi
                  andae, sed an harum simul dolor.
                </p> -->
                <div class="mt--40">
                  <h4 class="heading-title">¿Qué recibirás?</h4>
                  <p>Cuando el dibujo esté terminado, te enviaré una imagen digital del mismo para que puedas verlo y revisarlo. Si hay algo que te gustaría cambiar o ajustar, no dudes en hacérmelo saber y lo modificaré según tus indicaciones. Cuando estés completamente satisfecho con el dibujo, te enviaré una copia impresa del mismo para que puedas tenerlo como recuerdo o regalo especial.</p>
                  <h4 class="heading-title">¿Cuánto tiempo tarda?</h4>
                  <p>El tiempo que tarda en crear un dibujo a mano alzada dependerá del tamaño y la complejidad del dibujo. Por lo general, puedo completar un dibujo en una semana o dos. Sin embargo, si tienes una fecha límite específica, házmelo saber y haré todo lo posible para tenerlo listo a tiempo.</p>
                  <!-- <ul class="liststyle bullet">
                    <li>Yet this above sewed flirted opened ouch</li>
                    <li>Goldfinch realistic sporadic ingenuous</li>
                    <li>
                      Abominable this abidin far successfully then like piquan
                    </li>
                  </ul> -->
                </div>
                <!-- <p>
                  Etia reprimi no pri, vix no reque dolore di sentiet, vel at
                  wisi mazim feugiat. Ne ali quam apeirian mel, an ese choro
                  regione virtute per. iusto oporter and.
                </p> -->
              </div>
            </div>
          </v-col>
          <v-col md="6" cols="12" order-lg="2" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../assets/img/portfolio/portfolio-single-2.jpg"
                  alt="portfolio"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Service Details Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderFive from "../components/header/HeaderFive";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      HeaderFive,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .service-details-page {
    .-rn-footer {
      background: #f9f9f9;
    }
    .rp-port-details-inner {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
